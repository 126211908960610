import { GPTModels, MaxValueOptions, SettingData } from './types';

export const gptModels: GPTModels[] = [
  { value: GPTModels.GPT35Turbo, label: 'GPT-3.5-turbo' },
  { value: GPTModels.GPT4, label: 'GPT-4-turbo' },
  { value: GPTModels.GPT4o, label: 'GPT-4o' },
  { value: GPTModels.GPT4oMINI, label: 'GPT-4o-mini' },
  { value: GPTModels.CLAUDE3SONNET, label: 'Claude 3 Sonnet (Beta)' },
];

export const maxValueOptions: MaxValueOptions[] = [
  { value: MaxValueOptions.SHORT, label: 'Short' },
  { value: MaxValueOptions.MEDIUM, label: 'Medium' },
  { value: MaxValueOptions.LONG, label: 'Long' },
];

export const initialSettingsValue: SettingData = {
  basePrompt: `You're an intelligent assistant. You are focused on giving precise and helpful answers. Excel in multi-turn conversations and ask for clarification if needed.`,
  gptModel: GPTModels.GPT4o,
  maxTokens: MaxValueOptions.SHORT,
  maxInputLength: MaxValueOptions.SHORT,
  temperature: 0.1,
  aiName: 'AI Agent',
  company: null,
  isRestricted: false,
  isImageSharingEnabled: true,
  isLearnMoreEnabled: true,
  followUpQuestionEnabled: false,
  customDoNotKnowMessage: null,
  limiter: {
    alertText: 'Too many messages in a row',
    limit: 20,
    window: 240,
  },
  schedule: {
    status: false,
    timezone: 'Europe/London',
    dates: [
      {
        days: 'everyday',
        startTime: '08:30',
        endTime: '17:30',
      },
    ],
  },
};
