import utf8 from 'utf8';

import { Links } from '@/components/messenger/types';

export const isValidHttpUrl = (str: string) => {
  let url: URL;

  try {
    url = new URL(str);
  } catch {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const isValidUrl = (str: string) => {
  try {
    new URL(str);
    return true;
  } catch {
    return false;
  }
};

export const removeTrailingSlash = (str: string) => {
  return str.replace(/\/+$/, '');
};

export const cleanAndGetUrlAndId = (
  str: string,
): { urls: Links | undefined; id: string | undefined } | null => {
  try {
    const cleanStr = str?.replace('##Source##:', '');
    const data = JSON.parse(cleanStr);
    if (data.debugMessages) {
      data.debugMessages.forEach((message) => {
        console.error(message);
      });
    }
    const source: Links = data?.links || [];
    const id = data?.id;

    return {
      id,
      urls: source,
    };
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export const removeInvalidUTF8InString = (str: string): string => {
  const clearedStr = str.replace(/\0/g, '');
  const bytes = utf8.encode(clearedStr);
  return utf8.decode(bytes);
};

export const stringConvertHyperlink = (str: string): string => {
  try {
    const regex = /(https?:\/\/[^\s]+)/g;
    return str.replace(
      regex,
      '<a href="$1" target="_blank" rel="noreferrer">$1</a>',
    );
  } catch (e) {
    console.error(e);
    return str;
  }
};
