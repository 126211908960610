export type TranslationsFields = {
  newMessageToast: string;
  defaultError: string;
  invalidEmail: string;
  emailSuccess: string;
  newMessageBtn: string;
  you: string;
  sendPlaceholder: string;
  learnMore: string;
  resolutionQuestion: string;
  helped: string;
  notHelped: string;
  emailLabel: string;
  emailPlaceholder: string;
  dummyAiMessage: string;
  dummyUserMessage: string;
  supportRequestedTitle: string;
  supportRequestedDescription: string;
  joinText: string;
  aiIsWriting?: string;
  speaking: string;
};
const DEFAULT_ENGLISH: TranslationsFields = {
  newMessageToast: 'New message!',
  defaultError: 'Something went wrong',
  invalidEmail: 'Invalid email',
  emailSuccess: 'Email successfully saved',
  newMessageBtn: 'New message',
  you: 'You',
  sendPlaceholder: 'Send message',
  learnMore: 'Learn More',
  resolutionQuestion: 'This response helpful to you?',
  helped: 'That helped 👍',
  notHelped: "That didn't help 👎",
  emailLabel: 'Enter your email to get updates.',
  emailPlaceholder: 'Email...',
  dummyAiMessage: 'Dummy AI message',
  dummyUserMessage: 'Dummy user message',
  supportRequestedTitle: 'Human Support Requested 🎉',
  supportRequestedDescription:
    'We will send your request to all available agents, you will receive answers as soon as possible from here.',
  joinText: 'is joined the conversation',
  aiIsWriting: 'AI is writing',
  speaking: 'Speaking',
};

const DEFAULT_SPANISH: TranslationsFields = {
  newMessageToast: 'Nuevo mensaje!',
  defaultError: 'Algo salió mal',
  invalidEmail: 'Correo electrónico no válido',
  emailSuccess: 'Correo electrónico guardado correctamente',
  newMessageBtn: 'Nuevo mensaje',
  you: 'Usted',
  sendPlaceholder: 'Enviar mensaje',
  learnMore: 'Más información',
  resolutionQuestion: 'Le ha sido útil esta respuesta?',
  helped: 'Eso ayudó 👍',
  notHelped: 'Eso no ayudó 👎',
  emailLabel: 'Introduce tu correo electrónico para recibir actualizaciones.',
  emailPlaceholder: 'Correo electrónico...',
  dummyAiMessage: 'Mensaje AI ficticio',
  dummyUserMessage: 'Mensaje de usuario ficticio',
  supportRequestedTitle: 'Soporte humano solicitado 🎉',
  supportRequestedDescription:
    'Enviaremos su solicitud a todos los agentes disponibles, recibirá respuestas lo antes posible desde aquí.',
  joinText: 'se unió a la conversación',
  aiIsWriting: 'AI está escribiendo',
  speaking: 'Hablando',
};

const DEFAULT_GERMAN: TranslationsFields = {
  newMessageToast: 'Neue Nachricht!',
  defaultError: 'Etwas ist schief gelaufen',
  invalidEmail: 'Ungültige E-Mail',
  emailSuccess: 'E-Mail erfolgreich gespeichert',
  newMessageBtn: 'Neue Nachricht',
  you: 'Sie',
  sendPlaceholder: 'Nachricht senden',
  learnMore: 'Mehr erfahren',
  resolutionQuestion: 'Ist diese Antwort hilfreich für Sie?',
  helped: 'Das hat geholfen 👍',
  notHelped: 'Das hat nicht geholfen 👎',
  emailLabel: 'Geben Sie Ihre E-Mail-Adresse ein, um Updates zu erhalten.',
  emailPlaceholder: 'E-Mail...',
  dummyAiMessage: 'Dummy AI-Nachricht',
  dummyUserMessage: 'Dummy-Benutzernachricht',
  supportRequestedTitle: 'Menschliche Unterstützung angefordert 🎉',
  supportRequestedDescription:
    'Wir werden Ihre Anfrage an alle verfügbaren Agenten senden, Sie erhalten so schnell wie möglich Antworten von hier.',
  joinText: 'hat sich dem Gespräch angeschlossen',
  aiIsWriting: 'AI schreibt',
  speaking: 'Sprechen',
};

const DEFAULT_TURKISH: TranslationsFields = {
  newMessageToast: 'Yeni Mesaj!',
  defaultError: 'Bir şeyler ters gitti.',
  invalidEmail: 'Geçersiz e-posta',
  emailSuccess: 'E-posta başarıyla kayıt edildi.',
  newMessageBtn: 'Yeni Mesaj',
  you: 'Sen',
  sendPlaceholder: 'Mesaj Gönder',
  learnMore: 'Daha fazla bilgi',
  resolutionQuestion: 'Bu yanıt size yardımcı oldu mu?',
  helped: 'Yanıt yardımcı oldu 👍',
  notHelped: 'Yanıt yardımcı olmadı 👎',
  emailLabel: 'Güncellemeleri almak için e-postanızı girin.',
  emailPlaceholder: 'E-posta...',
  dummyAiMessage: 'Demo yapay zeka mesajı',
  dummyUserMessage: 'Demo kullanıcı mesajı',
  supportRequestedTitle: 'Destek Talep Edildi 🎉',
  supportRequestedDescription:
    'Talebinizi tüm mevcut temsilcilere göndereceğiz, buradan en kısa sürede cevaplar alacaksınız.',
  joinText: 'sohbete katıldı',
  aiIsWriting: 'AI yazıyor',
  speaking: 'Konuşuyor',
};

export { DEFAULT_ENGLISH, DEFAULT_SPANISH, DEFAULT_GERMAN, DEFAULT_TURKISH };
