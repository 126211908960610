import { LLMResult } from '@langchain/core/outputs';
import { ChatOpenAI } from '@langchain/openai';
import { Prisma } from '@prisma/client';
import { JsonOutputKeyToolsParser } from 'langchain/output_parsers';
import { NextRequest, NextResponse } from 'next/server';

import { INITIAL_TOKEN_USAGE, TokenUsage } from '@/constants/llm';
import getAzureCredentials from '@/helpers/getAzureCredentials';
import { getOpenAIModelName } from '@/helpers/getOpenAIModelName';
import { handleAndReturnAPIErrorResponse } from '@/lib/api/errors';
import PromptService from '@/services/PromptService';

export enum UserIntent {
  AI_HELPED_THE_USER = 'AI helped the user',
  USER_WANTS_HUMAN_SUPPORT = 'User wants human support',
  NEUTRAL = 'Neutral',
}

const userIntentAITool = {
  type: 'function' as const,
  function: {
    name: 'extractor',
    description:
      "Based on the user's message, identify the user's intent. The intent can be one of the following: AI helped the user, User wants human support, Neutral. Default is 'Neutral'.",
    parameters: {
      $schema: 'http://json-schema.org/draft-07/schema#',
      title: 'Improve with AI',
      type: 'object',
      properties: {
        intent: {
          type: 'string',
          enum: [
            UserIntent.AI_HELPED_THE_USER,
            UserIntent.USER_WANTS_HUMAN_SUPPORT,
            UserIntent.NEUTRAL,
          ],
          description: `
            The user intent.
            AI helped the user message example: ['thanks, that helped', 'got it', 'understood', 'makes sense', 'thank you']. 
            User wants human support message example: ['talk to a human', 'speak to someone', 'need human help', 'can i get a human', 'i want human support']
          `,
        },
      },
      required: ['intent'],
    },
  },
};

export async function POST(req: NextRequest) {
  try {
    let { message, openAIApiKey } = (await req.json()) as {
      message: string;
      openAIApiKey?: string;
    };

    let tokenUsage: TokenUsage = INITIAL_TOKEN_USAGE;
    const azureCredentials = getAzureCredentials('gpt-3.5-turbo');

    const llm = new ChatOpenAI({
      ...(openAIApiKey
        ? {
            openAIApiKey,
            modelName: getOpenAIModelName('gpt-3.5-turbo'),
          }
        : azureCredentials),
      verbose: true,
      streaming: false,
      callbacks: [
        {
          handleLLMEnd: (output: LLMResult) => {
            const usage = output?.llmOutput?.tokenUsage;
            tokenUsage.promptTokens = usage?.promptTokens || 0;
            tokenUsage.completionTokens = usage?.completionTokens || 0;
          },
        },
      ],
    });

    const promptService = new PromptService();
    const prompt = promptService.generateUserIntentPrompt();

    const llmWithTools = llm.bind({
      tools: [userIntentAITool],
      // force the model to call the tool.
      tool_choice: userIntentAITool,
    });

    const outputParser = new JsonOutputKeyToolsParser({
      keyName: 'extractor',
      returnSingle: true,
    });

    const chain = prompt.pipe(llmWithTools).pipe(outputParser);

    const result = await chain.invoke({ message });

    const returnResult: { intent: UserIntent; tokenUsage: TokenUsage } = {
      intent: result?.intent || UserIntent.NEUTRAL,
      tokenUsage,
    };

    return NextResponse.json({
      status: 'success',
      message: message,
      result: returnResult,
    });
  } catch (error) {
    return handleAndReturnAPIErrorResponse({
      request: req,
      err: error,
    });
  }
}
