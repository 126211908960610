import {
  AIMessage,
  HumanMessage,
  SystemMessage,
} from '@langchain/core/messages';
import {
  AIMessagePromptTemplate,
  ChatPromptTemplate,
  HumanMessagePromptTemplate,
  PromptTemplate,
  SystemMessagePromptTemplate,
} from '@langchain/core/prompts';
import { MessageType } from '@prisma/client';

import {
  GPTModel,
  GPTModels,
} from '@/app/(dashboard)/aibot/[chatbotId]/(layout)/settings/types';
import { Sources } from '@/helpers/getContextAndLinks';
import { getBase64Image } from '@/utils/file';
import { serializeChatHistory } from '@/utils/history';

export type PromptSettings = {
  name: string;
  company?: string;
  isRestricted: boolean;
  isImageSharingEnabled: boolean;
  customDoNotKnowMessage?: string;
};

type Question = {
  type: MessageType;
  text: string;
};

type GeneratePromptParams = {
  llmModel: GPTModel;
  basePrompt: string;
  settings: PromptSettings;
  sources: Sources;
  aiActionPrompts?: string;
  slackHistory?: string;
  pastMessages: (HumanMessage | AIMessage)[];
  question: Question;
};

export default class PromptService {
  private llmModel: GPTModel;
  private basePrompt: string;
  private settings: PromptSettings;
  private sources: Sources;
  private aiActionPrompts?: string;
  private pastMessages: (HumanMessage | AIMessage)[];

  private getSourceString(source: Sources[0]): string {
    switch (source.type) {
      case 'WEBSITE':
        return source?.link ? `Source: ${source.link}\n` : '';
      case 'QA':
        return 'Source: Question Answer Data Content\n';
      case 'TEXT':
        return 'Source: Text Data Content\n';
      case 'DOCX':
        return 'Source: Docx Data Content\n';
      case 'PDF':
        return 'Source: PDF Data Content\n';
      case 'IMAGE':
        return 'Source: Image Data Content\n';
      case 'YOUTUBE':
        return 'Source: Youtube Data Content\n';
      default:
        return '';
    }
  }

  private generateContextString(): {
    context: string;
    isGlobalContextEnabled: boolean;
  } {
    if (!this.sources) return { context: '', isGlobalContextEnabled: false };
    if (this.sources.length === 0)
      return { context: '', isGlobalContextEnabled: false };

    const contextString = this.sources
      .map((item, index) => {
        const isLastItem = index === this.sources.length - 1;
        const dividerString = isLastItem ? '' : '\n---\n';

        const sourceString = this.getSourceString(item);
        const scoreString = item?.distance
          ? `Similarity Score: ${item.distance}\n`
          : '';
        const contentString = item?.content ? `Context: ${item.content}\n` : '';
        const summaryString = item?.summary
          ? `Global Context: ${item.summary}\n`
          : '';

        return `${sourceString}${scoreString}${contentString}${summaryString}${dividerString}`;
      })
      .join('\n\n');

    return {
      context: contextString?.replace(/{/g, '{{').replace(/}/g, '}}'),
      isGlobalContextEnabled: this.sources.some((item) => item.summary),
    };
  }

  private getAIActionPrompt(): string {
    if (!this?.aiActionPrompts || this.aiActionPrompts?.trim() === '')
      return '';

    return `\n\nThe AI Functions Details:
--- start ai functions ---
${this.aiActionPrompts?.replace(/{/g, '{{').replace(/}/g, '}}')}
--- end ai functions ---`;
  }

  private getClaudeHistoryPrompt(): string {
    if (this.llmModel !== GPTModels.CLAUDE3SONNET) return '';

    return `\n\nThe history of the conversation:
--- start history ---
${serializeChatHistory(this.pastMessages?.reverse())}
--- end history ---`;
  }

  private getSlackHistoryString(slackHistory?: string): string {
    if (!slackHistory) return '';

    return `\n\nThe slack history of the conversation:
--- start history ---
${slackHistory}
--- end history ---`;
  }

  private getHistoryPrompt() {
    if (!this?.pastMessages || this.pastMessages?.length === 0) return [];

    const messages = this.pastMessages.map((message) => {
      const content = (message.content as string)
        .replace(/{/g, '{{')
        .replace(/}/g, '}}');

      if (message._getType() === 'ai') {
        return AIMessagePromptTemplate.fromTemplate(content);
      }

      return HumanMessagePromptTemplate.fromTemplate(content);
    });

    return messages;
  }
  private getNameAndCompanyPrompt() {
    const name = this.settings?.name;
    const company = this.settings?.company;

    const nameString = name ? `Your name is ${name}.` : '';
    const companyString = company ? `Your company is ${company}.` : '';

    return `${nameString} ${companyString}`;
  }
  private getChunkAndGlobalContentDefinitionPrompt({
    isGlobalContextEnabled,
  }: {
    isGlobalContextEnabled: boolean;
  }) {
    if (isGlobalContextEnabled) {
      return `You are given chunks of context and the transcript of the conversation between the user and you. In addition to these chunks, a 'Global Context' is provided for each document to summarize its main theme or key takeaway, ensuring a comprehensive understanding of the document's content, even when addressing broader questions or themes.

Source: The origin of this context, which can be a URL (for web pages), question-answer data, text data, docs data or PDF data.
Similarity Score: This represents how similar this piece of context is to the user's conversation for the individual chunks. For the Global Context, it indicates the overall relevance of the document's main theme to the conversation. The lower the score, the better the relevance.
Context: The actual content within each chunk that you need to use to respond to the user. For the Global Context, this is a summary that captures the essence of the entire document, whether it is derived from a web page, PDF, or text file.
Global Context: A concise summary of the document’s overall theme or objective, provided alongside individual content chunks. Use this to frame responses to broad inquiries or to ensure answers align with the overarching topic.
`;
    }

    return `You are given chunks of context and the transcript of the conversation between the user and you.

Source: The origin of this context, which can be a URL (for web pages), question-answer data, text data, docs data or PDF data.
Similarity Score: This represents how similar this piece of context is to the user's conversation for the individual chunks.
Context: The actual content within each chunk that you need to use to respond to the user.
`;
  }
  private getKnowledgeRestrictionPrompt() {
    const isRestricted = this.settings?.isRestricted || false;
    const customDoNotKnowMessage =
      this.settings?.customDoNotKnowMessage ||
      `I'm sorry, but I can't provide a definite answer based on the available context.`;

    if (isRestricted) {
      return `Rule: The AI must exclusively use the context to answer questions. Do not provide answers based on general or common knowledge or external information. If a query cannot be adequately addressed with the provided context, inform the user that the answer is not available within their specific data context.  If the context lacks the information, reply, "${customDoNotKnowMessage}"`;
    }

    return `Rule: The assistant should prioritize using the context to answer questions. However, if the context does not fully address the user's query, the Assistant may apply general knowledge or reasoning to provide a more comprehensive response. If both the context and general knowledge do not suffice, reply, "${customDoNotKnowMessage}"
Rule: When using general knowledge, the Assistant should clearly indicate which part of the response is derived from the provided context and which part is based on general knowledge or reasoning. This transparency can help users understand the basis of the responses they receive.`;
  }
  private getImageSharingPrompt() {
    const isImageSharingEnabled = this.settings?.isImageSharingEnabled || true;

    if (isImageSharingEnabled) {
      return `Rule: Include images in responses when they contribute to the answer. Ensure relevance and enhance understanding without compromising user experience.
* Use images that clearly add value to the response.
* Use images only in the context. Do not create image urls that are not in the context.
* Consider alt text describing the image's content or relevance.`;
    }

    return '';
  }

  private async getHumanMessagePrompt({
    question,
  }: {
    question: Question;
  }): Promise<HumanMessage> {
    if (question.type === MessageType.TEXT) {
      return new HumanMessage({
        content: [{ type: 'text', text: question.text }],
      });
    }

    // claude sonnet does not support image url. only accept base64 image
    if (this.llmModel === GPTModels.CLAUDE3SONNET) {
      const base64 = await getBase64Image(question.text);

      return new HumanMessage({
        content: [{ type: 'image_url', image_url: { url: base64 } }],
      });
    }

    // gpt-4o and gpt-4o-mini support image url
    return new HumanMessage({
      content: [{ type: 'image_url', image_url: { url: question.text } }],
    });
  }

  public async generatePrompt({
    llmModel,
    basePrompt,
    settings,
    sources,
    aiActionPrompts,
    slackHistory,
    pastMessages,
    question,
  }: GeneratePromptParams) {
    this.llmModel = llmModel;
    this.basePrompt = basePrompt;
    this.settings = settings;
    this.sources = sources;
    this.aiActionPrompts = aiActionPrompts;
    this.pastMessages = pastMessages;

    const { context, isGlobalContextEnabled } = this.generateContextString();

    const nameAndCompanyPrompt = this.getNameAndCompanyPrompt();
    const chunkAndGlobalContentDefinitionPrompt =
      this.getChunkAndGlobalContentDefinitionPrompt({ isGlobalContextEnabled });
    const knowledgeRestrictionPrompt = this.getKnowledgeRestrictionPrompt();
    const imageSharingPrompt = this.getImageSharingPrompt();

    const aiActionPromptString = this.getAIActionPrompt();
    const claudeHistoryPrompt = this.getClaudeHistoryPrompt();
    const slackHistoryString = this.getSlackHistoryString(slackHistory);

    const prompt = `
${nameAndCompanyPrompt} ${this.basePrompt}

${chunkAndGlobalContentDefinitionPrompt}

You must follow the rules. Let's start step by step:

${knowledgeRestrictionPrompt}
Rule: If the Assistant is uncertain about the user's intent or the question's context, it should seek clarification with specific, open-ended questions that guide the user to provide more detailed information.
Rule: Interact with understanding and respect.
Rule: The assistant must reply in markdown format.
Rule: The assistant must always respond in the same language as the user's message. If the user messages in English, respond in English. If the user messages in another language, respond in that language.
Rule: The assistant must provide general assistance without speculating if identifiers are not in the context.
Rule: The assistant must provide concise, informative summaries in its responses, aimed at delivering clarity and relevance. Although brevity is key, flexibility is permitted to ensure completeness and usefulness of information, especially for complex queries. The assistant is encouraged to include related links for detailed exploration, focusing on delivering core insights within the response itself.
Rule: When the Assistant encounters uncertainty in the user's intent or the context of the question, it should use the similarity scores to determine the most relevant contexts. If multiple contexts have high similarity scores, the Assistant may consider them collectively to form a more comprehensive understanding. The Assistant should consider the similarity scores as a dynamic aid in understanding user intent, not as an absolute measure. It's important to balance these scores with the need for clarity and relevance in the response. Scores should guide the decision-making process but not replace critical judgment and the need for user engagement when necessary.
Rule: The Assistant should encourage users to provide feedback on the usefulness of responses. Incorporate a simple mechanism for users to rate responses or offer suggestions for improvement. This feedback loop can help continuously refine and improve the Assistant's performance.
${imageSharingPrompt}
Rule: Share URLs only when directly relevant or requested by the user. Embed these links naturally within the response text to maintain flow and readability. Avoid overloading responses with multiple URLs to keep information clear and focused.
Rule: The assistant is strictly prohibited from including any citations with links in its responses, unless the source explicitly includes a valid URL. Under no circumstances should the assistant create or use URLs that are not directly provided in the context.

The context of chunks:
--- start context ---
${context}
--- end context ---
${claudeHistoryPrompt}
${aiActionPromptString}
${slackHistoryString}`
      ?.replace(/{/g, '{{')
      .replace(/}/g, '}}');

    const systemMessagePrompt =
      SystemMessagePromptTemplate.fromTemplate(prompt);
    const historyPrompt =
      llmModel === GPTModels.CLAUDE3SONNET ? [] : this.getHistoryPrompt();

    const humanQuestionPrompt = await this.getHumanMessagePrompt({ question });

    return ChatPromptTemplate.fromMessages([
      systemMessagePrompt,
      ...historyPrompt,
      humanQuestionPrompt,
    ]);
  }

  public generateExpanderPrompt() {
    const promptString = `Given the following conversation and a follow up question, rephrase the follow up \
question to be a standalone question.\
If it is not a follow-up question, rephrase the question to be a standalone question.\
Standalone question should always be in the same language as the last user message.

Chat History:
{chatHistory}
Follow Up Input: {question}
Standalone Question:`;

    const prompt = PromptTemplate.fromTemplate(promptString);

    return prompt;
  }

  public generateFollowUpQuestionsPrompt() {
    const promptString = `Enhance conversation by appending up to two interactive follow-up questions after each AI Assistant response. These questions, aimed at deepening the dialogue, encouraging further exploration of the subject matter.

You must follow the rules while generating follow-up questions. Let's start step by step:

Rule: The AI Assistant must first identify the language used in the Chat History.
Rule: The AI Assistant must generate follow-up questions in the same language with the Chat History. This ensures that all components of the response — both textual and visual — match the language used by the human and agent throughout the conversation.
Rule: Prior to presentation, the AI Assistant must conduct a thorough check to verify the language consistency between the content of follow-up questions, ensuring there are no discrepancies.
Rule: The AI Assistant must ensure that each question is unique and relevant to the Chat History, avoiding repetition and enhancing user engagement.
Rule: The AI Assistant must limit the selection to two follow-up questions to maintain a clear and directed conversation flow.

##Chat History:
{chatHistory}

##User Question:
{question}

##Format Instructions:
{format_instructions}
`;

    const prompt = PromptTemplate.fromTemplate(promptString);

    return prompt;
  }

  public generateMessageQualityCheckPrompt() {
    const promptString = `Your role is to review the conversations between human and artificial intelligence and classified the AI's messages.

Rule: The AI must understand the language in the conversation and must treat all conversations equally, regardless of the language.
Rule: The AI must accurately identify messages where it cannot provide a relevant or complete response due to insufficient context or limitations in its knowledge base. It should distinguish between entirely out-of-context inquiries and those to which it can provide partial but informative answers.

## NOT_ANSWERED
**Description:** Use this tag when the AI fails to provide any substantive response to the user's query, essentially leaving the question completely unaddressed.
**Conditions:**
- Irrelevant Response: The AI's response is entirely unrelated to the question asked.
- Lack of Content: The AI provides a generic response that does not contain any specific information pertinent to the query.

<start_example>

## Example Evaluated Conversation Analysis:

## Given Example Conversation:
**Message ID user-message:**
User: Hey

**Message ID id1:**
AI: Hi, how can I assist you?

**Message ID user-message:**
User: I would like to know more about your services.

**Message ID id2:**
AI: I'm sorry, I don't know about our service.

**Message ID user-message:**
User: Can you tell me about the benefits?

**Message ID id3:**
AI: Our platform offers a variety of benefits.

## Example Output:
{{
  ids: [
    {{ "messageId": "id1", "status": "GREETING" }},
    {{ "messageId": "id2", "status": "NOT_ANSWERED" }},
    {{ "messageId": "id3", "status": "NEED_IMPROVEMENT" }}
  ]
}}

<end_example>

<start_conversation>

{messages}

<end_conversation>

<start_format_instructions>

{format_instructions}

<end_format_instructions>
`;

    const prompt = PromptTemplate.fromTemplate(promptString);

    return prompt;
  }

  public generateQAPrompt() {
    const promptString = `I am a highly intelligent question answering robot. I will create and answer 3 faq questions according to the given context. 
    
    <start_content>
    
    {content}
    
    <end_content>
    `;

    const prompt = PromptTemplate.fromTemplate(promptString);

    return prompt;
  }

  public generateUserIntentPrompt() {
    const promptString = `AI assistant should classify whether the user's message includes any of the specified phrases or similar expressions indicating a preference for human assistance, dissatisfaction with the AI response, or confusion. Examples include, but are not limited to:

    Direct requests for human support: 'talk to a human,' 'speak to someone,' 'need human help,' 'can I get a human,' 'I want human support,' 'I need to speak with a human.'
    Expressions of dissatisfaction: 'this doesn't answer my question,' 'this is not helpful,' 'you're not understanding,' 'I'm not getting the answer I need,' 'this isn't what I was looking for.'
    Expressions of confusion: 'this is confusing,' 'I don't understand,' 'I'm not sure what you mean,' 'I'm lost,' 'I don't get it.'"

    <UserMessage>
    
    {message}
    
    <UserMessage>
    `;

    const prompt = PromptTemplate.fromTemplate(promptString);

    return prompt;
  }

  public improveWithAI() {
    const promptString = `As an expert in Semantic Analysis, Markdown Transformation, and Semantic Document Structuring & Chunking, your task is to process a markdown document and convert it into semantically distinct, well-structured chunks. Follow these rules:

    1. **Semantic Analysis**: Analyze the content to understand its meaning and context.
    2. **Headline Transformation**: Convert markdown-style headlines into descriptive labels. For instance, "# Email Marketing Best Practices" or "## What is Email Marketing?".
    3. **Headline Identification**: If headlines aren't in markdown style, use semantic analysis to identify and label them appropriately.
    4. **Content Integrity**: Preserve the original content and markdown codes without any changes. Don't delete markdown image codes from the content.
    5. **Image Inclusion**: Include images in the content if the original content contains image markdown codes. Maintain the original image URLs.
    6. **Clarity and Conciseness**: Make the content concise, clear, and semantically coherent.
    7. **Content Chunking**: Segment the transformed content into distinct, well-defined chunks.
    8. **Question Creation**: Generate search engine optimized questions based on each chunk segment.
    9. **Summary Creation**: Generate a concise summary of 2-3 sentences that captures the main themes, ideas, and information from the content while ensuring coherence and accurately representing the essence of the original texts.
    
    <Content>
    
    {content}
    
    <Content>`;

    const prompt = PromptTemplate.fromTemplate(promptString);

    return prompt;
  }

  public mergeSummariesPrompt() {
    const promptString = `You are an AI assistant with expertise in text summarization. 
    Your task is to analyze and merge provided summaries into a coherent and concise paragraph of 2-3 sentences. 
    Focus on extracting and clearly presenting the main themes, ideas, and information from the summaries while ensuring that the output is understandable and effectively captures the essence of the original texts.
    
    <Content>
    
    {content}
    
    <Content>`;

    const prompt = PromptTemplate.fromTemplate(promptString);

    return prompt;
  }
}
